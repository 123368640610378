<li role="menuitem">
  <a tabindex="0" style="padding-left: 0px" (click)="handleClick()">
    <div class="p-mr-2">
      <span class="fa-stack k-fa-15x">
        <i
          class="_icon_back fas fa-square fa-stack-2x"
          [style.color]="notifikacija.iconDto.backgroundColor"
        ></i>
        <i
          [ngClass]="'_icon_front fa-stack-1x ' + notifikacija.iconDto.iconName"
          [style.color]="notifikacija.iconDto.iconColor"
        ></i>
      </span>
    </div>

    <div class="notification-item">
      <div class="notification-summary p-text-bold">
        {{ notifikacija.naslov }}
      </div>
      <div class="notification-detail">{{ notifikacija.tekst }}</div>
    </div>
  </a>
</li>
