<div class="p-mb-3 p-fluid" style="margin-top: -18px">
  <p-messages severity="info">
    <ng-template pTemplate>
      <div class="p-d-flex p-jc-between" style="width: 100%">
        <div class="p-d-flex p-flex-column p-jc-center gap-6">
          <div>
            Molimo Vas da proverite svoja dugovanja i ukoliko postoje izmirite ih da biste izbegli deaktivaciju naloga. Vaš eKompanija tim.
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-jc-center">
            <span
              class="p-message-icon pi pi-info-circle"
              style="font-size: 2.5rem"
            ></span>
        </div>
      </div>
    </ng-template>
  </p-messages>
</div>
