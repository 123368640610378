<li role="menuitem">
  <a tabindex="0" style="padding-left: 0px" (click)="startTour()">
    <div class="p-mr-2">
      <span class="fa-stack k-fa-15x">
        <i
          class="_icon_back fas fa-square fa-stack-2x"
          style="color: #FFFBEB;"
        ></i>
        <i
          class="_icon_front fa-stack-1x far fa-question"
          style="color: #B6690D;"
        ></i>
      </span>
    </div>

    <div class="notification-item">
      <div class="notification-summary p-text-bold">
        {{ tutorijal.naziv }}
      </div>
    </div>
  </a>
</li>
