import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import {
  ApiMenadzmentDto,
  FilterChipDto,
  GetSefRacunRelevantniDokumentListZippedQuery,
  GetSefRacunTableQuery,
  IGetSefRacunTableQuery,
  INeobradjenSefRacunTableDto,
  MojaFirmaClient,
  ResultOfApiMenadzmentDto,
  ResultOfIReadOnlyListOfGetSefDokumentResponse,
  SefClient,
  StatusRacuna,
  SyncUlazneRacuneCommand,
  TipPristupa,
  TipRacuna,
} from '@kodit/core/data-api';
import { AlertService, SefService, SharedService } from '@kodit/core/services';
import { nameofFactory, toCamelCase } from '@kodit/core/shared';
import {
  ActionClass,
  ActionMode,
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  IPaginatedResultTableDto,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { Subscription } from 'rxjs';
import { NeobradjeniSefRacuniFilterComponent } from '../neobradjeni-sef-racuni-filter/neobradjeni-sef-racuni-filter.component';
import { NeobradjeniSefRacuniFilterService } from '../neobradjeni-sef-racuni-filter/neobradjeni-sef-racuni-filter.service';
import { OdobravanjeOdbacivanjeRacunaFormComponent } from '../odobravanje-odbacivanje-racuna-form/odobravanje-odbacivanje-racuna-form.component';
import { finalize } from 'rxjs/operators';
import { NeobradjenRacunFilesFormComponent } from '../neobradjen-racun-files-form/neobradjen-racun-files-form';
import {
  DialogSize,
  OnDynamicDialogTypedInit,
} from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { LazyLoadEvent } from 'primeng/api';
import { SinhronizacijaFormComponent } from './sinhronizacija-form/sinhronizacija-form.component';
import { CreateOdobrenRacunFormComponent } from '../create-odobren-racun-form/create-odobren-racun-form.component';
import { RazlogStorniranjaOdbijanjaDialogComponent } from '../razlog-storniranja-odbijanja-dialog/razlog-storniranja-odbijanja-dialog.component';
import { MojaFirmaApiCardFormComponent } from 'libs/core/moduli/podesavanje/src/lib/moja-firma/moja-firma-api-card-form/moja-firma-api-card-form.component';
import { RelevantniDokumentPreviewComponent } from '../../info/relevantni-dokument-preview/relevantni-dokument-preview.component';

@Component({
  selector: 'kodit-neobradjen-sef-racun-table',
  templateUrl: './neobradjen-sef-racun-table.component.html',
  styleUrls: ['./neobradjen-sef-racun-table.component.scss'],
})
export class NeobradjenSefRacunTableComponent
  implements
    OnInit,
    OnDestroy,
    OnTableInit,
    OnDynamicDialogTypedInit<OdobravanjeOdbacivanjeRacunaFormComponent>,
    OnDynamicDialogTypedInit<OdobravanjeOdbacivanjeRacunaFormComponent> {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  numberOfRowsDisplayed: number = 0;

  /** Props */
  paginatedData: IPaginatedResultTableDto;
  tableItems: INeobradjenSefRacunTableDto[] = [];
  chipItems: FilterChipDto[] = [];
  private _skipFirst = 0;
  private _isSefActive = false;
  apiMenadzmentDto: ApiMenadzmentDto;
  errorMessage: string =
    "API KEY za SEF nije validan. Proverite unetu vrednost u 'Podesavanja/Kompanija/API_KEY' i pokušajte ponovo.";
  showTableMassage: boolean = false;

  constructor(
    private _sefClient: SefClient,
    private _loader: LoaderService,
    private _filterService: NeobradjeniSefRacuniFilterService,
    private _dialogService: DynamicDialogService,
    private _sharedService: SharedService,
    private _sefService: SefService,
    private _alertService: AlertService,
    private _mojaFirmaClient: MojaFirmaClient
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();

    this._subs.add(
      this._mojaFirmaClient.getApiMenadzment().subscribe((res) => {
        this.apiMenadzmentDto = res.data;
      })
    );

    this._subs.add(
      this._filterService.getAdvencedFilterSubmitted.subscribe(
        (filterResult) => {
          if (filterResult?.shouldApplyFilter) {
            this.tableConfig.advancedFilter.data = {
              ...filterResult.filterData,
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            };
            this._load(this.tableConfig.advancedFilter.data);
          }
        }
      )
    );

    this._subs.add(
      this._sefService.getIsSefActive.subscribe((isSefActive) => {
        if (this._isSefActive != isSefActive.isActive) {
          this._isSefActive = isSefActive.isActive;
          this._syncWithSef();
        }
      })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Neobrađeni ulazni računi sa SEF-a',
      selectMode: SelectMode.MULTI,
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        this._skipFirst = event.first;
        this.numberOfRowsDisplayed = event.rows;
        this._updateFilterDataAndReload();
      },
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        {
          header: 'Tip',
          type: 'badge',
          field: 'tipDto',
          subField: 'tipStr',
          styleClassField: 'tipBadgeStr',
          styleClass: 'tip-racuna',
          tooltipField: 'opis',
        },
        {
          field: 'broj',
          header: 'Broj',
          type: 'text',
        },
        {
          field: 'datumValuteStr',
          header: 'Datum valute',
          type: 'text',
        },
        {
          field: 'datumPrometaStr',
          header: 'Datum prometa',
          type: 'text',
        },
        {
          field: 'stranka',
          header: 'Stranka',
          type: 'text',
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'status-racuna',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'iznos',
          header: 'Iznos',
          currencyAlphaCharField: 'valutaText',
          type: 'currency'
        },
      ],
      headerActions: [
        {
          //isVisible: false,   // TODO: UKLONITI KADA SE RESI BACKGROUND SERVIS !
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Sinhronizuj',
          icon: 'fa-light fa-rotate',
          shouldDisableWhenSefInactive: true,
          hasAccessTooltip:
            'Sinhronizujte listu ulaznih računa sa SEF-om 10 računa',
          noAccessTooltip:
            'Nemate ovlašćenja za sinhronizaciju statusa izlaznih računa',
          callback: () => {
            this._openSinhronizujDialog();
          },
        },
        {
          /* Preuzimanje vise neobradjenih racuna u PDF formatu */
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Preuzmi PDF',
          icon: 'fa-solid fa-download',
          hasAccessTooltip: 'Preuzmi fakturu/e u PDF formatu',
          callback: (result: INeobradjenSefRacunTableDto[]) =>
            this._downloadMultiplePdf(result.map((ulazna) => ulazna.sefId)),
        },
        {
          /* Odobravanje vise racuna */
          type: ActionType.MULTI_CUSTOM,
          actionClass: 'p-button-outlined p-button-success',
          label: 'Odobri račune',
          icon: 'fa-light fa-thumbs-up',
          shouldDisableWhenSefInactive: true,
          hasAccessTooltip: 'Odobri odabrane račune',
          callback: (result: INeobradjenSefRacunTableDto[]) => {
            if (
              result.some((item) => item.statusRacuna === StatusRacuna.PRIHVACENO)
            ) {
              this._alertService.addWarnMsg(
                'Akcija odobravanja nije moguća. Neki od selektovanih računa je već odobren.'
              );
              return;
            }
            this._configOdobravanjeOdbacivanjeDialog(
              true,
              result.map((res) => res.sefId)
            );

            this.openDialog(OdobravanjeOdbacivanjeRacunaFormComponent);
          },
        },
        {
          /* Odbacivanje vise racuna */
          type: ActionType.MULTI_CUSTOM,
          actionClass: 'p-button-outlined p-button-danger',
          label: 'Odbaci račune',
          icon: 'fa-light fa-thumbs-down',
          shouldDisableWhenSefInactive: true,
          hasAccessTooltip: 'Odbaci odabrane račune',
          callback: (result: INeobradjenSefRacunTableDto[]) => {
            if (
              !result.every(
                (item) =>
                  item.statusRacuna === StatusRacuna.PREGLEDANO ||
                  item.statusRacuna === StatusRacuna.NOVO
              )
            ) {
              this._alertService.addWarnMsg(
                'Akcija odbijanja nije moguća. Neki od selektovanih računa je već odbijen ili odobren.'
              );
              return;
            }
            this._configOdobravanjeOdbacivanjeDialog(
              false,
              result.map((res) => res.sefId)
            );
            this.openDialog(OdobravanjeOdbacivanjeRacunaFormComponent);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-thumbs-up',
          actionClass: 'p-button-success',
          tipPristupa: TipPristupa.ULAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Odobrite račun',
          noAccessTooltip: 'Nemate ovlašćenja za prihvatanje računa',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._configOdobravanjeOdbacivanjeDialog(
              true,
              [this.tableItems[this._getPaginatedIndex(index)].sefId],
              this.tableItems[this._getPaginatedIndex(index)].broj,
              this.tableItems[this._getPaginatedIndex(index)].tipRacuna
            );
            this.dialogConfig.setDialogSize = DialogSize.MEDIUM_LARGE;

            this.openDialog(OdobravanjeOdbacivanjeRacunaFormComponent);
          },
          shouldDisplayByCondition: (rowData: INeobradjenSefRacunTableDto) =>
            this._shouldDisplayOdobri(rowData.statusDto.status),
        },
        {
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-thumbs-down',
          actionClass: 'p-button-danger',
          tipPristupa: TipPristupa.ULAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Odbacite račun',
          noAccessTooltip: 'Nemate ovlašćenja za odbacivanje računa',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._configOdobravanjeOdbacivanjeDialog(
              false,
              [this.tableItems[this._getPaginatedIndex(index)].sefId],
              this.tableItems[this._getPaginatedIndex(index)].broj
            );
            this.dialogConfig.setDialogSize = DialogSize.MEDIUM_LARGE;
            this.openDialog(OdobravanjeOdbacivanjeRacunaFormComponent);
          },
          shouldDisplayByCondition: (rowData: INeobradjenSefRacunTableDto) =>
            this._shouldDisplayOdbaci(rowData.statusDto.status),
        },
        {
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-plus',
          actionClass: 'p-button-success',
          tipPristupa: TipPristupa.ULAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Kreirajte ulazni račun',
          noAccessTooltip: 'Nemate ovlašćenja za kreiranje računa',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._configCreateOdobrenRacunDialog(
              [this.tableItems[this._getPaginatedIndex(index)].sefId],
              this.tableItems[this._getPaginatedIndex(index)].broj,
              this.tableItems[this._getPaginatedIndex(index)].tipRacuna
            );
            this.dialogConfig.setDialogSize = DialogSize.SMALL;

            this.openDialog(CreateOdobrenRacunFormComponent);
          },
          shouldDisplayByCondition: (rowData: INeobradjenSefRacunTableDto) =>
            !rowData.jeKreiranUlazniRacun &&
            rowData.statusDto.status == StatusRacuna.PRIHVACENO,
        },
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-eye',
          label: 'Pregledaj PDF',
          hasAccessTooltip: 'Pregledajte PDF primljenog računa',
          actionClass: 'p-button-primary',
          noAccessTooltip: 'Nemate ovlašćenje za pregledanje računa',
          callback: (index: number) => {
            if (this._getPaginatedItem(index).hasRelevantneDokumente) {
              // open new dialog
              this.dialogConfig.header =
                'Prilozi računa ' + this._getPaginatedItem(index).broj;
              this.dialogConfig.hideFooter = true;
              this.dialogConfig.dialogSize = DialogSize.MEDIUM;
              this.dialogConfig.data = {
                sefId: this._getPaginatedItem(index).sefId,
              };
              // configure dialog
              this.openDialog(NeobradjenRacunFilesFormComponent);
            } else {
              this._handlePdfPreview(index);
            }
          },
        },
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-download',
          label: 'Preuzmi UBL',
          hasAccessTooltip: 'Preuzmite UBL primljenog računa',
          actionClass: 'p-button-primary',
          shouldDisableWhenSefInactive: true,
          noAccessTooltip: 'Nemate ovlašćenje za pregledanje računa',
          callback: (index: number) => {
            this._getNeobradjenRacunUbl(index);
          },
        },
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-comment',
          label: 'Pregledaj komentar',
          hasAccessTooltip:
            'Pregledajte razlog odbijanja, storniranja ili otkazivanja računa',
          actionClass: 'p-button-primary',
          noAccessTooltip: 'Nemate ovlašćenje za pregledanje računa',
          callback: (index: number) => {
            const status = this._getPaginatedItem(index).statusDto.status;
            this.dialogConfig.header =
              'Razlog ' +
              (status === StatusRacuna.ODBIJENO
                ? ' odbijanja'
                : status === StatusRacuna.STORNIRANO
                ? 'storniranja'
                : 'otkazivanja') +
              ' računa';
            this.dialogConfig.hideFooter = true;
            this.dialogConfig.maximisable = false;
            this.dialogConfig.dialogSize = DialogSize.SMALL_TINY;
            //this.dialogConfig.setDialogSize = DialogSize.SMALL_TINY;
            this.dialogConfig.data = {
              komentar: this._getPaginatedItem(index)
                .razlogOtkazivanjaOdbijanja,
            };
            this.openDialog(RazlogStorniranjaOdbijanjaDialogComponent);
          },
          shouldDisplayByCondition: (rowData: INeobradjenSefRacunTableDto) =>
            rowData.statusDto.status == StatusRacuna.ODBIJENO ||
            rowData.statusDto.status == StatusRacuna.STORNIRANO ||
            rowData.statusDto.status == StatusRacuna.OTKAZANO,
        },
      ],
      advancedFilter: {
        component: NeobradjeniSefRacuniFilterComponent,
        data: new GetSefRacunTableQuery(),
      },
    });
  }

  private _configCreateOdobrenRacunDialog(
    sefIds: number[],
    broj: string,
    tipRacuna: TipRacuna
  ) {
    this.dialogConfig.data = {
      sefIds: sefIds,
      brojRacuna: broj,
      tipRacuna: tipRacuna,
    };

    this.dialogConfig.header = 'Kreiranje prihvaćenog računa';
    this.dialogConfig.customSubmitButton = {
      icon: 'fa-light fa-plus',
      label: 'Kreiraj',
      class: 'mw-90',
    };
    this.dialogConfig.customCancelButton = {
      icon: 'far fa-window-close',
      label: 'Otkaži',
    };

    this.dialogConfig.hideFooter = false;
  }

  openDialog(
    formType:
      | Type<OdobravanjeOdbacivanjeRacunaFormComponent>
      | Type<NeobradjenRacunFilesFormComponent>
      | Type<CreateOdobrenRacunFormComponent>
      | Type<RazlogStorniranjaOdbijanjaDialogComponent>
  ): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);
    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this._setDefaultFilters();
        }
      })
    );
  }

  private _openSinhronizujDialog() {
    this.dialogConfig.hideFooter = false;
    this.dialogConfig.setDialogSize = DialogSize.SMALL;
    this.dialogConfig.maximisable = false;
    this.dialogConfig.customSubmitButton = { label: 'Sinhronizuj' } as any;
    this.dialogConfig.customCancelButton = { label: 'Otkaži' } as any;
    this.dialogConfig.header = 'Sinhronizuj SEF račune';
    const ref = this._dialogService.open(
      SinhronizacijaFormComponent,
      this.dialogConfig
    );

    ref.onClose.subscribe(() => {
      this._updateFilterDataAndReload();
    });
  }

  private _configOdobravanjeOdbacivanjeDialog(
    jeOdobravanje: boolean,
    sefIds: number[],
    broj?: string,
    tipRacuna?: TipRacuna
  ) {
    this.dialogConfig.data = {
      jeOdobravanje: jeOdobravanje,
      sefIds: sefIds,
      brojRacuna: broj,
      tipRacuna: tipRacuna,
    };
    this.dialogConfig.header = jeOdobravanje
      ? 'Prihvatanje računa'
      : 'Odbacivanje računa';
    if (this.dialogConfig.data.jeOdobravanje) {
      this.dialogConfig.customSubmitButton = {
        icon: 'fa-solid fa-check',
        label: 'Odobri',
        class: 'mw-90',
      };
    } else {
      this.dialogConfig.customSubmitButton = {
        icon: 'fa-solid fa-check',
        label: 'Odbaci',
        class: 'mw-90',
      };
    }
    this.dialogConfig.customCancelButton = {
      icon: 'far fa-window-close',
      label: 'Otkaži',
    };
    this.dialogConfig.dialogSize = DialogSize.SMALL;
    this.dialogConfig.hideFooter = false;
  }

  private async _downloadMultiplePdf(sefIds: number[]) {
    this._subs.add(
      this._sefClient
        .getSefRacunRelevantniDokumentListZipped(
          new GetSefRacunRelevantniDokumentListZippedQuery({
            sefIds: sefIds,
          })
        )
        .subscribe((res) => {
          const url = window.URL.createObjectURL(res.data);
          window.open(url);
        })
    );
  }

  private async _handlePdfPreview(rowIndex: number) {
    this._subs.add(
      this._sefClient
        .getSefRacunRelevantniDokumentList(
          this._getPaginatedItem(rowIndex).sefId
        )
        .subscribe((res) => {
          this.openPdfDialog(res);
        })
    );
  }

  private openPdfDialog(res: ResultOfIReadOnlyListOfGetSefDokumentResponse) {
    this.dialogConfig.data = {
      fileUrl: res.data.find((x) => !x.jePrilog).fileUrl,
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.hideSubmit = true;
    this.dialogConfig.hideCancel = true;
    this._dialogService.open(
      RelevantniDokumentPreviewComponent,
      this.dialogConfig
    );
  }

  private async _getNeobradjenRacunUbl(rowIndex: number) {
    this._subs.add(
      this._sefClient
        .getNeobradjenRacunUblQuery(this._getPaginatedItem(rowIndex).sefId)
        .subscribe((res) => {
          const xmlFileUrl = URL.createObjectURL(res.data);
          this._sharedService.downloadUrlAsXml(
            xmlFileUrl,
            (res.data as any).fileName
          );
        })
    );
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    // ToDo: promeniti ovu logiku, jer bi morala da se primenjuje na svakoj komponenti koja zove status multiselect.
    //  Treba da bude negde u shared

    const nameof = nameofFactory<IGetSefRacunTableQuery>();

    const nameOfStatusi = nameof('statusi');

    if (removedChip.key.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter
        .data as IGetSefRacunTableQuery).statusi.findIndex(
        (x) => x.status === removedChip.defaultValue
      );

      (this.tableConfig.advancedFilter
        .data as IGetSefRacunTableQuery).statusi.splice(idx, 1);
    } else {
      this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
        removedChip.defaultValue;
    }

    this._updateFilterDataAndReload();
  }

  private _load(request?: IGetSefRacunTableQuery) {
    this._subs.add(
      this._sefClient
        .getSefRacunTable(request as GetSefRacunTableQuery)
        .subscribe((res) => {
          this.paginatedData = res;
          this.tableItems = res.data;
          this.chipItems = res.activeFilters;
        })
    );
  }

  private _shouldDisplayOdbaci(status: StatusRacuna) {
    return (
      status === StatusRacuna.NOVO ||
      status === StatusRacuna.PREGLEDANO ||
      status === StatusRacuna.PODSTETNIK_POSLAT
    );
  }

  private _shouldDisplayOdobri(status: StatusRacuna) {
    return (
      status === StatusRacuna.NOVO ||
      status === StatusRacuna.PREGLEDANO ||
      status === StatusRacuna.PODSTETNIK_POSLAT ||
      status === StatusRacuna.ODBIJENO
    );
  }

  private _syncWithSef() {
    if (!this._isSefActive) {
      this._alertService.addWarnMsg(
        'Nije moguće odraditi sinhronizaciju sa SEF-om pa će biti prikazani samo lokalni sef računi'
      );
      this._setDefaultFilters();
      return;
    }

    this._loader.setLoadingText = 'Sinhronizujem se sa SEF-om...';
    this._loader.setShowLoader = true;

    this._subs.add(
      this._sefClient
        .syncUlazneRacune(new SyncUlazneRacuneCommand())
        .pipe(
          finalize(() => {
            this._loader.reset();
          })
        )
        .subscribe((res) => {
          if (res.succeeded && res.data.length > 0) {
            this._alertService.addSuccessMsg(res.data);
            if(res.messages[0].length > 0){
              this._alertService.addWarnMsg(res.messages[0]);
            }
            this._setDefaultFilters();
          } else {
            this.showTableMassage = true;
          }
        })
    );
  }

  handleAddApiKey() {
    this.dialogConfig.data = {
      apiKeySef: this.apiMenadzmentDto.apiKeySEF,
    };
    this.dialogConfig.header = 'Unos API ključa';

    this.openApiDialog(this.dialogConfig);
  }

  openApiDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(MojaFirmaApiCardFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: ResultOfApiMenadzmentDto) => {
        if (dto) {
          this.apiMenadzmentDto = dto.data;
          location.reload();
        }
      })
    );
  }

  private _setDefaultFilters() {
    this._filterService.setAdvancedFilterSubmitted = {
      filterData: {
        statusi: [],
      },
      shouldApplyFilter: true,
    };
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): INeobradjenSefRacunTableDto {
    return this.tableItems[this._getPaginatedIndex(index)];
  }

  private _updateFilterDataAndReload() {
    this.tableConfig.advancedFilter.data.pageNumber = this._skipFirst;
    this.tableConfig.advancedFilter.data.pageSize = this.numberOfRowsDisplayed;
    this._load(this.tableConfig.advancedFilter.data);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
