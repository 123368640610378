<kodit-info-container>
  <div body-full>
    <kodit-moja-firma-dodatne-informacije></kodit-moja-firma-dodatne-informacije>

    <div class="p-mt-5">
      <kodit-moja-firma-api-card></kodit-moja-firma-api-card>
    </div>

    <div class="p-mt-5 kodit-card-tab">
      <p-tabView>
        <p-tabPanel header="Tekući računi">
          <div card>
            <!-- <kodit-bankovni-nalog-table></kodit-bankovni-nalog-table> -->
            <kodit-tekuci-racun-table></kodit-tekuci-racun-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Poslovne jedinice">
          <div card>
            <kodit-poslovna-jedinica-table></kodit-poslovna-jedinica-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</kodit-info-container>
