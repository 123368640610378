<div
  class="p-d-flex p-flex-column p-flex-md-row gap-25 srednji-kurs-wrapper"
  *ngIf="exchangeRateDtoList.length > 1"
>
  <div>
    <span>{{ exchangeRateDtoList[0].currencyConversion }}</span>
    {{ exchangeRateDtoList[0].middleRate | currencyNumber: null:4 }}
    <span
      ><kodit-helper-icon
        [helperText]="
          'Srednji kurs za datum \n' + exchangeRateDtoList[0].date + '.'
        "
      ></kodit-helper-icon
    ></span>
  </div>
  <div>
    <span>{{ exchangeRateDtoList[1].currencyConversion }}</span>
    {{ exchangeRateDtoList[1].middleRate | currencyNumber: null:4 }}
    <span
      ><kodit-helper-icon
        [helperText]="
          'Srednji kurs za datum \n' + exchangeRateDtoList[1].date + '.'
        "
      ></kodit-helper-icon
    ></span>
  </div>
</div>
