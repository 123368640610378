import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { IPdvEvidencijaPojedinacnaDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { PdvEvidencijaPojedinacnaService } from '../pdv-evidencija-pojedinacna.service';

@Component({
  selector: 'kodit-povezani-avansni-racuni',
  templateUrl: './povezani-avansni-racuni.component.html',
  styleUrls: ['./povezani-avansni-racuni.component.scss'],
})
export class PovezaniAvansniRacuniComponent implements OnInit {
  @Input() form!: FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto>;
  @Input() shouldDisable: boolean = false;

  constructor(
    private _pdvPojedinacnaService: PdvEvidencijaPojedinacnaService
  ) {}

  ngOnInit(): void {}

  onRemovePovezanAvansniRacun(index: number) {
    (this.form.controls.povezaniDokumenti as FormArray).removeAt(index);
  }
}
