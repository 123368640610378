<div class="p-d-flex p-fluid p-flex-column">
  <kodit-field fieldLabel="Naziv" fieldLabelFor="naziv">
    <kodit-text-input formCtrlId="naziv" [formCtrl]="form.controls.naziv">
    </kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="JID" fieldLabelFor="jid">
    <kodit-text-input formCtrlId="jid" [formCtrl]="form.controls.jid">
    </kodit-text-input>
  </kodit-field>

  <kodit-field fieldLabel="Lozinka" fieldLabelFor="lozinka">
    <kodit-text-input formCtrlId="lozinka" [formCtrl]="form.controls.lozinka">
    </kodit-text-input>
  </kodit-field>

  <kodit-field fieldLabel="Pak" fieldLabelFor="pak">
    <kodit-text-input formCtrlId="pak" [formCtrl]="form.controls.pak">
    </kodit-text-input>
  </kodit-field>

  <kodit-field fieldLabel="Poslovna jedinica" fieldLabelFor="poslovnaJedinica">
    <kodit-poslovne-jedinice-dropdown
      (onPoslovnaJedinicaChanged)="handlePoslovnaJedinicaChanged($event)"
    ></kodit-poslovne-jedinice-dropdown>
    <small *ngIf="!imaMagacina" class="p-invalid">
      *** Nema magacina za odabranu poslovnu jedinicu, molimo vas da prvo
      povežete magacin za poslovnu jedinicu
    </small>
  </kodit-field>

  <kodit-file-upload
    (onUpload)="handleUpload($event)"
    [uploadCompleted]="uploadCompleted"
    fileTypes=".pfx"
    [shouldAutoUpload]="true"
    descriptionText="Izaberite sertifikat koji želite da otpremite ili ga prevucite ovde"
  ></kodit-file-upload>
</div>
