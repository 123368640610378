<li role="menuitem">
    <div class="notification-item">
      <div class="notification-summary">
      <div class="p-mb-2">
        {{naziv}}
      </div>
        <p-progressBar
          card-subtitle
          [value]="progressPercent"
          [ngClass]="'progress'"
          ></p-progressBar>
      </div>
    </div>
</li>
