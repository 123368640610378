<div class="p-list p-mt-5">
  <!-- Korigovati naslov i podnaslov -->

  <!-- KPO knjiga -->
  <kodit-izvestaj-card
    class="p-col-12 p-md-6 p-lg-4"
    izvestajCardTitle="KPO knjiga"
    izvestajCardSubtitle="Podešavanja i pregeled KPO knjige i njeno generisanje u PDF format"
    cardIcon="far fa-book"
    cardLink="kpo-knjiga"
  ></kodit-izvestaj-card>

  <ng-container *ngIf="shouldDisplayFiskalna">
    <!-- Dnevni izvestaj -->
    <kodit-izvestaj-card
      class="p-col-12 p-md-6 p-lg-4"
      izvestajCardTitle="Dnevni izveštaj fiskalne kase"
      izvestajCardSubtitle="Pregled dnevnog izveštaja fiskalnih kase i njeno štampanje"
      cardIcon="fa-solid fa-cash-register"
      cardLink="fiskalna-kasa-dnevni-izvestaj"
    ></kodit-izvestaj-card>

    <!-- Periodcni izvestaj -->
    <kodit-izvestaj-card
      class="p-col-12 p-md-6 p-lg-4"
      izvestajCardTitle="Periodični izveštaj fiskalne kase"
      izvestajCardSubtitle="Pregled periodičnog izveštaja fiskalne kase i njeno štampanje"
      cardIcon="fa-solid fa-cash-register"
      cardLink="fiskalna-kasa-periodicni-izvestaj"
    ></kodit-izvestaj-card>
  </ng-container>

  <!-- IOS izvestaj -->
  <kodit-izvestaj-card
    class="p-col-12 p-md-6 p-lg-4"
    izvestajCardTitle="IOS izveštaj"
    izvestajCardSubtitle="Izvod otvorenih stavki - pregled dugovanja i potraživanja"
    cardIcon="fa-solid fa-magnifying-glass-chart"
    cardLink="ios-izvestaj"
  ></kodit-izvestaj-card>
</div>
