import { Component, Input, OnInit } from '@angular/core';
import { JoyrideHandleService, Tutorial } from '@kodit/core/services';

@Component({
  selector: 'kodit-tutorial-item',
  templateUrl: './tutorial-item.component.html',
  styleUrls: ['./tutorial-item.component.scss']
})
export class TutorialItemComponent implements OnInit {
  @Input() tutorijal: Tutorial
  constructor(private _joyrideHandleService: JoyrideHandleService) { }

  ngOnInit(): void {
  }

  startTour() {
    this._joyrideHandleService.startTutorial(this.tutorijal.steps);
  }
}
