<!-- Template za joyride step 3(tutorial button) -->
<ng-template #tutorialJoyride>
  <p>Ovde mozete naci jos znacajnih tutoriala</p>
</ng-template>

<div class="sticky layout-topbar">
  <!-- <button
                                          type="button"
                                          pButton
                                          pRipple
                                          icon="fa-solid fa-grid-2 "
                                          class="p-button-text"
                                        ></button> -->

  <a [routerLink]="['/dashboard']" class="logo">
    <img
      alt="kodit-logo"
      class="logo-image"
      id="app-logo"
      src="/assets/core-ui/images/ek-blue-black.svg"
    />
  </a>
  <a
    (click)="appLayout.onMenuButtonClick($event)"
    *ngIf="false"
    class="menu-button"
    tabindex="0"
  >
    <i class="fa-solid fa-bars"></i>
  </a>

  <div class="topbar-wrapper">
    <div class="layout-topbar p-p-0">
      <div class="topbar-left">
        <img
          alt="kodit-logo"
          class="mobile-logo"
          id="logo-mobile"
          src="/assets/core-ui/images/eKompanija_logo_h_siva.png"
        />

        <!-- Firma / firme -->
        <div *ngIf="korisnikTenantAccesses$ | async as availableTenants">
          <kodit-tenant-name
            *ngIf="availableTenants.korisnikTenantAccesses.length === 1"
            [currentTenant]="availableTenants.korisnikTenantAccesses[0]"
          ></kodit-tenant-name>
          <kodit-korisnik-tenant-dropdown
            *ngIf="availableTenants.korisnikTenantAccesses.length > 1"
            [availableTenants]="availableTenants"
          ></kodit-korisnik-tenant-dropdown>
        </div>
        <div class="p-ml-2 godine">
          <kodit-godina-racuna-dropdown
            *ngIf="
              shouldDisplayRacunYearFilter &&
              !shouldDisplayFiskalnaKasaStavkeRacunaTitle
            "
          ></kodit-godina-racuna-dropdown>
        </div>
      </div>

      <div class="topbar-center">
        <kodit-srednji-kurs-za-valute></kodit-srednji-kurs-za-valute>
      </div>

      <div class="topbar-right">
        <ul class="topbar-menu">
          <!-- Escd card status -->
          <!-- <li
            class="esdc-card-status-item p-d-flex p-ai-center"
            *ngIf="esdcCardStatusDto?.backgroundColor"
            [pTooltip]="
              esdcCardStatusDto?.cardStatus === esdcCardNotInserted
                ? 'Smart kartica nije prisutna'
                : esdcCardStatusDto?.cardStatus === esdcCardInserted
                ? 'Smart kartica je detekovana ali fali pin'
                : 'LPFR Spreman za rad'
            "
            tooltipPosition="bottom"
          >
            <i
              [ngClass]="
                esdcCardStatusDto?.cardStatus === esdcCardNotInserted
                  ? 'esdc-not-inserted blink fa-solid fa-microchip'
                  : 'fa-solid fa-microchip'
              "
              [ngStyle]="{
                color: esdcCardStatusDto?.backgroundColor
              }"
            ></i>
            <span class="p-ml-2"
            >LPFR
              {{
              esdcCardStatusDto?.cardStatus == esdcCardInsertedAndVerified
                ? 'operativan'
                : 'nedostupan'
              }}</span
            >
          </li> -->

          <!-- Sef health status -->
          <li
            *ngIf="isSefActiveDto?.background"
            [pTooltip]="
              isSefActiveDto?.isActive
                ? 'Sistem elektronskih faktura je operativan'
                : 'Sistem elektronskih faktura je nedostupan'
            "
            class="sef-health-item p-d-flex p-ai-center"
            tooltipPosition="bottom"
          >
            <span
              [ngClass]="
                isSefActiveDto?.isActive
                  ? 'sef-active p-badge'
                  : 'sef-inactive blink p-badge'
              "
              [ngStyle]="{ background: isSefActiveDto?.background }"
            ></span>
            <span class="p-ml-2"
              >SEF
              {{ isSefActiveDto.isActive ? 'operativan' : 'nedostupan' }}</span
            >
          </li>

          <!-- Notifikacije -->

          <li
            [ngClass]="{
              'active-menuitem ': appLayout.topbarNotificationMenuActive
            }"
            class="notifications-item"
          >
            <a
              (click)="appLayout.onTopbarNotificationMenuButtonClick($event)"
              class="profile-item-a"
              href="#"
              tabindex="0"
            >
              <i class="fa-light fa-bell"></i>
              <span
                *ngIf="notifikacijeKorisnika.length > 0"
                class="topbar-badge"
                >{{
                  notifikacijeKorisnika.length > 5
                    ? '5+'
                    : notifikacijeKorisnika.length
                }}</span
              >
            </a>
            <ul id="notificationBox" style="z-index: 99999999 !important">
              <div class="p-d-flex p-p-2">
                <span style="font-weight: 600; font-size: medium"
                  >Obaveštenja</span
                >
                <p-button
                  (click)="handleOznaciKaoProcitano()"
                  *ngIf="notifikacijeKorisnika.length > 0"
                  label="Označi kao pročitano"
                  styleClass="p-button-link"
                ></p-button>
              </div>
              <kodit-divider></kodit-divider>
              <ng-container *ngIf="notifikacijeKorisnika.length > 0">
                <kodit-notification-item
                  *ngFor="
                    let notifikacija of notifikacijeKorisnika | slice: 0:5
                  "
                  [notifikacija]="notifikacija"
                ></kodit-notification-item>
              </ng-container>
              <ng-container *ngIf="notifikacijeKorisnika.length == 0">
                <span class="p-text-italic p-p-2"
                  >Nemate novih obaveštenja</span
                >
              </ng-container>
              <kodit-divider></kodit-divider>
              <div class="p-d-flex p-p-2">
                <p-button
                  (click)="gotToNotifikacije()"
                  label="Pogledaj sva obaveštenja"
                  styleClass="p-button-link"
                ></p-button>
              </div>
            </ul>
          </li>

          <!-- Tutorial -->

          <li
            *ngIf="tutorijali.length > 0"
            [ngClass]="{
              'active-menuitem ': appLayout.topbarTutorialMenuActive
            }"
            [stepContent]="tutorialJoyride"
            class="tutorial-item"
            joyrideStep="thirdStep"
            title="Pomoć"
          >
            <a
              (click)="appLayout.onTopbarTutorialMenuButtonClick($event)"
              class="profile-item-a"
              href="#"
              tabindex="0"
            >
              <i class="fal fa-question"></i>
            </a>
            <ul id="tutorialBox">
              <div class="p-d-flex p-p-2">
                <span style="font-weight: 600; font-size: medium"
                  >Tutorijali</span
                >
              </div>
              <kodit-divider></kodit-divider>
              <ng-container *ngIf="tutorijali.length > 0">
                <kodit-tutorial-item
                  *ngFor="let tutorijal of tutorijali"
                  [tutorijal]="tutorijal"
                >
                </kodit-tutorial-item>
              </ng-container>
              <ng-container *ngIf="tutorijali.length == 0">
                <span class="p-text-italic p-p-2"
                  >Nema dostupnih tutorijala!</span
                >
              </ng-container>
            </ul>
          </li>

          <!-- Progress -->

          <li
            *ngIf="progresses.length > 0"
            [ngClass]="{
              'active-menuitem ': appLayout.topbarProgressMenuActive
            }"
            class="progress-item"
            title="Progres"
          >
            <a
              (click)="appLayout.onTopbarProgressMenuButtonClick($event)"
              class="profile-item-a"
              href="#"
              tabindex="0"
            >
              <i class="fal fa-bars-progress"></i>
            </a>
            <ul id="progressBox">
              <div class="p-d-flex p-p-2">
                <span style="font-weight: 600; font-size: medium"
                  >Progress</span
                >
              </div>
              <kodit-divider></kodit-divider>
              <ng-container *ngIf="progresses.length > 0">
                <kodit-progress-item
                  *ngFor="let progress of progresses"
                  [progress]="progress"
                >
                </kodit-progress-item>
              </ng-container>
              <!-- <ng-container *ngIf="progresses.length == 0">
                <span class="p-text-italic p-p-2"
                  >Trenutno se ne izvršava nijedan proces</span
                >
              </ng-container> -->
            </ul>
          </li>

          <!-- Naziv korisnika -->
          <li
            [ngClass]="{
              'active-menuitem fadeInDown': appLayout.topbarUserMenuActive
            }"
            class="profile-item p-ml-5"
          >
            <a
              (click)="appLayout.onTopbarUserMenuButtonClick($event)"
              class="profile-item-a"
              href="#"
            >
              <div
                class="profile-image"
                height="50"
                id="avatar-initials"
                width="50"
              ></div>
              <span class="profile-name">{{ name }}</span>
            </a>
            <ul
              class="profile-menu fade-in-up"
              style="z-index: 999999999 !important"
            >
              <li>
                <!-- <a href="#">
                                                                                                                                                                                                                                                                                                                              <i class="pi pi-user"></i>
                                                                                                                                                                                                                                                                                                                              <span>Profil</span>
                                                                                                                                                                                                                                                                                                                            </a> -->
              </li>
              <li>
                <a routerLink="/podesavanja">
                  <i class="pi pi-cog"></i>
                  <span>Podešavanja</span>
                </a>
              </li>
              <!-- <li>
                                                                                                                                                                                                                                                                                        <a href="#">
                                                                                                                                                                                                                                                                                          <i class="pi pi-calendar"></i>
                                                                                                                                                                                                                                                                                          <span>Kalendar</span>
                                                                                                                                                                                                                                                                                        </a>
                                                                                                                                                                                                                                                                                      </li> -->
              <!-- <li>
                                                                                                                                                                                                                                                                                        <a href="#">
                                                                                                                                                                                                                                                                                          <i class="pi pi-inbox"></i>
                                                                                                                                                                                                                                                                                          <span>Sanduče</span>
                                                                                                                                                                                                                                                                                        </a>s
                                                                                                                                                                                                                                                                                      </li> -->
              <li>
                <a (click)="logOff()">
                  <i class="pi pi-power-off"></i>
                  <span>Odjava</span>
                </a>
              </li>
            </ul>
          </li>

          <!-- <li class="right-sidebar-item">
                                                                                                                                                                                                      <a href="#" tabindex="0" (click)="appLayout.onRightMenuClick($event)">
                                                                                                                                                                                                        <i class="pi pi-align-right"></i>
                                                                                                                                                                                                      </a>
                                                                                                                                                                                                    </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
