<ng-container>
  <div *ngIf="root && rootVisible" class="layout-menuitem-root-text">
    {{ item.label }}
  </div>
  <a
    [attr.href]="item.url"
    (click)="itemClick($event)"
    *ngIf="!item.routerLink || item.items"
    (mouseenter)="onMouseEnter()"
    (keydown.enter)="itemClick($event)"
    [ngClass]="item.class"
    pRipple
    [attr.target]="item.target"
    [attr.tabindex]="0"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <i
      class="pi pi-fw pi-chevron-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>
  <a
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    *ngIf="item.routerLink && !item.items"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [ngClass]="item.class"
    pRipple
    [attr.target]="item.target"
    [attr.tabindex]="0"
    badge="8"
    badgeClass="p-badge-danger"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    <span
      *ngIf="item.notification ? item.notification() : false"
      class="p-inline-flex p-ai-center p-jc-center p-ml-auto p-badge p-component p-badge-no-gutter"
      style="min-width: 1.5rem; height: 1.5rem"
      >{{ item.notification() }}</span
    >

    <i
      class="pi pi-fw pi-chevron-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>

  <ul
    *ngIf="item.items"
    role="menu"
    [@children]="
      app.isSlim()
        ? root
          ? app.isMobile()
            ? 'visible'
            : slimClick
            ? active
              ? 'slimVisibleAnimated'
              : 'slimHiddenAnimated'
            : active
            ? 'visible'
            : 'hidden'
          : active
          ? 'visible'
          : 'hidden'
        : root
        ? 'visible'
        : active
        ? 'visibleAnimated'
        : 'hiddenAnimated'
    "
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li
        class="k-menu-item"
        kodit-menu-item
        *ngIf="child.isVisible == undefined || child.isVisible"
        [item]="child"
        [index]="i"
        [parentKey]="key"
        [class]="child.badgeClass"
        (click)="child.onClick ? child.onClick() : null"
      ></li>
    </ng-template>
  </ul>
</ng-container>
