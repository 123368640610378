<div *ngIf="racunInfoDto" class="p-grid">
  <div class="p-col-12">
    <div class="p-col-12 p-d-flex p-jc-between">
      <div *ngIf="shouldDisplayAkcije">
        <!-- <button
          icon="far fa-file-pdf"
          pButton
          type="button"
          label="Skini .pdf"
          class="p-mr-2 p-button p-button-outlined"
          (click)="onDownload()"
          [disabled]="shouldDisablePreviewAndDownload"
        ></button> -->
      </div>

      <div *ngIf="shouldDisplayEdit" class="p-d-flex p-flex-row">
        <div>
          <button
            (click)="onEdit()"
            class="p-mr-2 p-button p-button-success p-button-outlined"
            icon="far fa-edit"
            label="Izmeni"
            pButton
            type="button"
          ></button>
        </div>
        <div *ngIf="shouldDisplayPosaljiButton">
          <button (click)="menu.toggle($event)" pButton type="button">
            Pošalji
          </button>
          <p-menu #menu [model]="posaljiItems" [popup]="true"></p-menu>
        </div>
        <!-- <button
          icon="far fa-trash-alt"
          pButton
          type="button"
          label="Arhiviraj"
          class="p-mr-2 p-button p-button-danger p-button-outlined"
          (click)="onDownload()"
        ></button> -->
      </div>
    </div>
  </div>

  <div class="p-col-8">
    <div class="p-mb-5">
      <kodit-osnovne-informacije></kodit-osnovne-informacije>
    </div>
    <div class="p-mb-5">
      <kodit-oznake-racuna-info [oznake]="racunInfoDto.oznakeDto" [racunId]="racunInfoDto.id" [canDelete]="getCanDeleteOznake"></kodit-oznake-racuna-info>
    </div>
    <div *ngIf="shouldDisplayAvansneUplateRacuna" class="p-mb-5">
      <kodit-avansne-uplate-racuna-card
        [racunId]="racunInfoDto.id"
      ></kodit-avansne-uplate-racuna-card>
    </div>
    <div *ngIf="shouldDisplayUplatePoRacunu" class="p-mb-5">
      <kodit-uplate-po-racunu></kodit-uplate-po-racunu>
    </div>
    <div *ngIf="shouldDisplayIskoriscenostAvansnogRacuna" class="p-mb-5">
      <kodit-iskoriscenost-avansnog-racuna></kodit-iskoriscenost-avansnog-racuna>
    </div>
    <div *ngIf="relevantniDokumenti.length > 0" class="p-mb-5">
      <kodit-relevantni-dokumenti-po-racunu
        [dokumentList]="relevantniDokumenti"
        [shouldDisplayDownloadUbl]="shouldDisplayDownloadUbl"
        [shouldDisableDownloadUbl]="shouldDisableDownloadUbl"
        [showSyncBtn]="true"
        (downloadUblClicked)="onUblDownload()"
        btnLabel="Sinhronizuj dokumente"
      ></kodit-relevantni-dokumenti-po-racunu>
    </div>
    <div *ngIf="emailPorukeRacuna.length > 0" class="p-mb-5">
      <kodit-email-poruke-po-racunu
        [emailList]="emailPorukeRacuna"
      ></kodit-email-poruke-po-racunu>
    </div>

    <!-- <div class="p-mb-5">
      <kodit-izmene-osnovice-po-racunu></kodit-izmene-osnovice-po-racunu>
    </div>
    <div class="p-mb-5">
      <kodit-avansne-uplate-po-racunu></kodit-avansne-uplate-po-racunu>
    </div>
    <div>
      <kodit-storno-racuna></kodit-storno-racuna>
    </div> -->
  </div>

  <div *ngIf="shouldDisplayEmptyDocumentMsg" class="p-col-4">
    <h4>Ne postoji dokument za trenutni račun</h4>
    <p class="p-text-justify">
      Trenutno su povezani dokumenti omogućeni jedino za SEF račune
    </p>
  </div>

  <div *ngIf="shouldDisplayRacunImage" class="p-col-4">
    <p-image
      [preview]="true"
      [src]="'data:image/png;base64,' + racunImage"
      alt="Image"
      imageClass="p-col-12"
    ></p-image>
  </div>

  <div class="p-col-4">
    <div
      *ngIf="shouldDisplayRacunPdf"
      [innerHTML]="pdfInnerHtml"
      style="height: 71vh"
    ></div>

    <div *ngIf="shouldDisplayUploadSkeleton" class="loading-card">
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
    </div>

    <!-- <div class="p-mb-5">
      <kodit-odobravanja-racuna></kodit-odobravanja-racuna>
    </div> -->
    <!-- <div class="p-mb-5">
      <kodit-relevantni-dokumenti-po-racunu></kodit-relevantni-dokumenti-po-racunu>
    </div>
    <div class="p-mb-5">
      <kodit-istorijat-racuna> </kodit-istorijat-racuna>
    </div> -->
  </div>
</div>


<div *ngIf="!racunInfoDto" class="p-grid">
  <div class="p-col-8">
    <div class="loading-card">
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
    </div>
  </div>

  <div class="p-col-4">
    <div class="loading-card">
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
    </div>
  </div>
</div>
