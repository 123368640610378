<div
  class="p-d-flex p-flex-column p-mt-3"
  *ngIf="avansneUplateTable?.length > 0"
>
  <p-table
    [value]="avansneUplateTable.controls"
    styleClass="p-datatable-striped p-datatable-gridlines"
    dataKey="id"
    editMode="row"
    responsiveLayout="scroll"
  >
    <!-- START HEADER -->
    <ng-template pTemplate="header">
      <!-- header columns -->
      <tr class="columns">
        <th style="width: 7vw">Avansni račun</th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayS20">
          S20
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayPdv20">
          PDV 20%
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayS10">
          S10
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayPdv10">
          PDV 10%
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayS8">
          S8
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayPdv8">
          PDV 8%
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayAE">
          AE
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayO">
          O
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayE">
          E
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayR">
          R
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayZ">
          Z
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplaySS">
          SS
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayOE">
          OE
        </th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayN">
          N
        </th>
        <th style="width: 7vw" *ngIf="shouldDisplayActions"></th>
      </tr>
    </ng-template>
    <!-- START BODY -->
    <ng-template
      pTemplate="body"
      let-uplata
      let-rowIndex="rowIndex"
      let-editing="editing"
    >
      <ng-container>
        <tr [pEditableRow]="uplata">
          <!-- Broj avansnog racuna -->
          <td class="p-text-center">
            <button
              pButton
              type="button"
              [label]="uplata.get('avansniRacunBroj').value"
              class="p-text-left p-button-link p-p-0 text-underline"
              (click)="goToAvansniRacun(rowIndex)"
            ></button>
          </td>
          <!-- S20 -->
          <td class="p-text-right" *ngIf="shouldDisplayS20">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-field class="p-fluid">
                  <kodit-number-input
                    [formCtrl]="uplata.get('evidentiranaOsnovica20')"
                    formCtrlId="s20"
                  ></kodit-number-input>
                </kodit-field>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovica20').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- PDV 20% -->
          <td class="p-text-right" *ngIf="shouldDisplayPdv20">
            {{ uplata.get('evidentiranPdv20').value | decimalNumber }}
          </td>
          <!-- S10 -->
          <td class="p-text-right" *ngIf="shouldDisplayS10">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-field>
                  <kodit-number-input
                    [formCtrl]="uplata.get('evidentiranaOsnovica10')"
                    formCtrlId="s10"
                  ></kodit-number-input>
                </kodit-field>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovica10').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- PDV 10% -->
          <td class="p-text-right" *ngIf="shouldDisplayPdv10">
            {{ uplata.get('evidentiranPdv10').value | decimalNumber }}
          </td>
          <!-- S8 -->
          <td class="p-text-right" *ngIf="shouldDisplayS8">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-field>
                  <kodit-number-input
                    [formCtrl]="uplata.get('evidentiranaOsnovica8')"
                    formCtrlId="s8"
                  ></kodit-number-input>
                </kodit-field>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovica8').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- PDV 8% -->
          <td class="p-text-right" *ngIf="shouldDisplayPdv8">
            {{ uplata.get('evidentiranPdv8').value | decimalNumber }}
          </td>
          <!-- AE -->
          <td class="p-text-right" *ngIf="shouldDisplayAE">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaAE')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaAE').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- O -->
          <td class="p-text-right" *ngIf="shouldDisplayO">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaO')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaO').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- E -->
          <td class="p-text-right" *ngIf="shouldDisplayE">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaE')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaE').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- R -->
          <td class="p-text-right" *ngIf="shouldDisplayR">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaR')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaR').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- Z -->
          <td class="p-text-right" *ngIf="shouldDisplayZ">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaZ')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaZ').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- SS -->
          <td class="p-text-right" *ngIf="shouldDisplaySS">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaSS')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaSS').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- OE -->
          <td class="p-text-right" *ngIf="shouldDisplayOE">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaOE')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaOE').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- N -->
          <td class="p-text-right" *ngIf="shouldDisplayN">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <kodit-number-input
                  [formCtrl]="uplata.get('evidentiranaOsnovicaN')"
                ></kodit-number-input>
              </ng-template>
              <ng-template pTemplate="output">
                {{ uplata.get('evidentiranaOsnovicaN').value | decimalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- akcije -->
          <td style="text-align: center" *ngIf="shouldDisplayActions">
            <button
              *ngIf="!editing"
              pButton
              pRipple
              type="button"
              pInitEditableRow
              icon="far fa-edit"
              (click)="onRowEditInit(uplata)"
              class="p-button-rounded p-button-text p-button-success"
            ></button>
            <button
              *ngIf="!editing"
              pButton
              pRipple
              type="button"
              icon="fa-regular fa-trash-alt"
              (click)="onRowDelete(rowIndex)"
              class="p-button-rounded p-button-text p-button-danger"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pSaveEditableRow
              icon="fa-solid fa-check"
              class="p-button-rounded p-button-text p-button-success mr-2"
              (click)="onRowEditSave(uplata, rowIndex)"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pCancelEditableRow
              icon="fa-solid fa-x"
              (click)="onRowEditCancel(uplata, rowIndex)"
              class="p-button-rounded p-button-text p-button-danger"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
</div>

<div *ngIf="shouldDisplayMsg" class="p-text-left">
  Na računu nema registrovanih avansnih uplata
</div>
