import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogService} from "@kodit/core/shared-ui";
import {DialogSize} from "../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config";
import {VerzijaSoftveraDijalogComponent} from "./verzija-softvera-dijalog/verzija-softvera-dijalog.component";

@Component({
  selector: 'kodit-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  currentYear: number=new Date().getFullYear();

  constructor(private _dialogService: DynamicDialogService) { }

  ngOnInit(): void {

  }

  openVerzijaSoftveraDijalog(){
    this.dialogConfig.hideSubmit = true;
    this.dialogConfig.customCancelButton = {
      icon: 'far fa-window-close',
      label: 'Zatvori',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.header = "Informacije o softveru";
    this._dialogService.open(
      VerzijaSoftveraDijalogComponent,
      this.dialogConfig
    );
  }
}
