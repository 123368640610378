<!-- Template za joyride step 2(topbar) -->
<ng-template #topbarJoyride>
  <p>Na vrhu stranice se nalazi topbar</p>
</ng-template>

<!-- Template za joyride slanjeNaSefVideo step -->
<ng-template #slanjeNaSefContent>
  <div
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative; width: 50vw"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <iframe
        allow="autoplay; fullscreen"
        allowtransparency="true"
        class="wistia_embed"
        frameborder="0"
        height="100%"
        msallowfullscreen
        name="wistia_embed"
        scrolling="no"
        src="https://fast.wistia.net/embed/iframe/rmyla29eqb?seo=true&videoFoam=true"
        title="slanje_na_sef Video"
        width="100%"
      ></iframe>
    </div>
  </div>
  <script async src="https://fast.wistia.net/assets/external/E-v1.js"></script>
</ng-template>

<!-- Template za joyride slanjeNaEmailVideo step -->
<ng-template #slanjeNaMailContent>
  <div
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative; width: 50vw"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <iframe
        allow="autoplay; fullscreen"
        allowtransparency="true"
        class="wistia_embed"
        frameborder="0"
        height="100%"
        msallowfullscreen
        name="wistia_embed"
        scrolling="no"
        src="https://fast.wistia.net/embed/iframe/bkkl5cx36g?seo=true&videoFoam=true"
        title="slanje_na_email Video"
        width="100%"
      ></iframe>
    </div>
  </div>
  <script async src="https://fast.wistia.net/assets/external/E-v1.js"></script>
</ng-template>

<!-- Template za joyride pravljenjeDuplikataVideo step -->
<ng-template #pravljenjeDuplikataContent>
  <div
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative; width: 50vw"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <iframe
        allow="autoplay; fullscreen"
        allowtransparency="true"
        class="wistia_embed"
        frameborder="0"
        height="100%"
        msallowfullscreen
        name="wistia_embed"
        scrolling="no"
        src="https://fast.wistia.net/embed/iframe/vr1at1iurr?seo=true&videoFoam=true"
        title="duplikat_fakture Video"
        width="100%"
      ></iframe>
    </div>
  </div>
  <script async src="https://fast.wistia.net/assets/external/E-v1.js"></script>
</ng-template>

<kodit-loader [content]="content" [showLoader]="showLoadingPage"></kodit-loader>

<kodit-blockable-div #content>
  <div
    (click)="onLayoutClick()"
    [attr.data-theme]="colorScheme"
    [class]="colorScheme === 'light' ? menuTheme : ''"
    [ngClass]="{
      'layout-overlay': menuMode === 'overlay',
      'layout-static': menuMode === 'static',
      'layout-slim': menuMode === 'slim',
      'layout-sidebar-dim': colorScheme === 'dim',
      'layout-sidebar-dark': colorScheme === 'dark',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive':
        staticMenuDesktopInactive && menuMode === 'static',
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple
    }"
    class="layout-wrapper"
  >
    <div
      [stepContent]="topbarJoyride"
      joyrideStep="secondStep"
      style="position: sticky; top: 0px; z-index: 999; border-bottom: 2px solid #EAECF0;"
      title="Topbar"
      title="Topbar"
    >
      <kodit-topbar></kodit-topbar>
    </div>

    <div class="layout-content-wrapper">
      <div
        *ngIf="!jePrviLoginKompanije && !jePrviLoginKorisnika"
        class="layout-content"
      >
        <joyrideStep
          [stepContent]="slanjeNaSefContent"
          joyrideStep="slanjeNaSefStep"
          stepPosition="center"
          title="Slanje fakture na SEF"
        >
        </joyrideStep>

        <joyrideStep
          [stepContent]="slanjeNaMailContent"
          joyrideStep="slanjeNaEmailStep"
          stepPosition="center"
          title="Slanje fakture na E-mail"
        >
        </joyrideStep>

        <joyrideStep
          [stepContent]="pravljenjeDuplikataContent"
          joyrideStep="pravljenjeDuplikataStep"
          stepPosition="center"
          title="Pravljenje duplikata fakture"
        >
        </joyrideStep>

        <ng-container [ngTemplateOutlet]="obavestenje"></ng-container>

        <div *ngIf="isSefActiveDto?.isActive == false" class="p-mb-3 p-fluid">
          <p-message
            severity="warn"
            text="Sef nije u funkciji! Sve akcije vezane za sef su trenutno nedostupne."
          ></p-message>
        </div>

        <div class="p-mb-3 p-d-flex p-jc-between">
          <xng-breadcrumb
            [preserveFragment]="false"
            [separator]="separatorTemplate"
          >
            <ng-container
              *xngBreadcrumbItem="
                let breadcrumb;
                let info = info;
                let first = first
              "
            >
              <!-- <ng-container *ngIf="first && !info">                                                                                                                                                              <i class="fa-regular fa-angle-right p-mr-2"></i>
                                                                          </ng-container> -->
              <ng-container>{{ breadcrumb }}</ng-container>
            </ng-container>
          </xng-breadcrumb>
          <div *ngIf="shouldDisplayFiskalnaKasaStavkeRacunaTitle" class="fiskalna-kasa-stavke-title-card">
            <div class="p-flex align-items-start justify-content-center fiskalna-kasa-stavke-title">
              STAVKE RAČUNA
            </div>
          </div>
        </div>

        <router-outlet></router-outlet>
      </div>

      <div
        *ngIf="jePrviLoginKompanije || jePrviLoginKorisnika"
        class="layout-content"
      >
        <kodit-welcome-kompanija-card
          *ngIf="jePrviLoginKompanije"
        ></kodit-welcome-kompanija-card>
        <kodit-welcome-korisnik-card
          *ngIf="jePrviLoginKorisnika"
        ></kodit-welcome-korisnik-card>
      </div>
      <kodit-init></kodit-init>
      <kodit-footer></kodit-footer>
    </div>

    <kodit-menu></kodit-menu>

    <div class="layout-mask modal-in"></div>

    <p-toast position="bottom-center"></p-toast>

    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

    <kodit-tawk-chat></kodit-tawk-chat>

    <kodit-rating *ngIf="showRating"></kodit-rating>
  </div>
</kodit-blockable-div>

<ng-template #separatorTemplate>
  <i class="fa-regular fa-angle-right"></i>
</ng-template>

<ng-template #obavestenje>
  <kodit-global-notification></kodit-global-notification>
</ng-template>
