import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-dugovanje-notification',
  templateUrl: './dugovanje-notification.component.html',
  styleUrls: ['./dugovanje-notification.component.scss']
})
export class DugovanjeNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
