<div class="p-field">
  <label for="pretraga-oznake" kodit-form-label>Dodajte oznake</label>
  <span class="p-input-icon-left pretraga-box">
    <i class="fas fa-search"></i>
    <p-autoComplete
      inputId="pretraga-oznake"
      #oznakaInput
      appendTo="body"
      [suggestions]="oznakeResults"
      field="naziv"
      placeholder="Naziv oznake..."
      pTooltip="Unesi naziv oznake za pretragu"
      (completeMethod)="searchOznake($event)"
      (onSelect)="onOznakaSelect($event)"
    >
      <ng-template let-oznaka pTemplate="item">
        <div>
          {{ oznaka.punNaziv ? oznaka.punNaziv : oznaka.naziv }}
        </div>
      </ng-template>
    </p-autoComplete>
  </span>
</div>

<div
  class="p-mr-2 p-mb-4 my-p-chip"
  *ngFor="let oznaka of oznakeArray.controls; index as i"
>
  <ng-container>
    <span class="fas fa-tag my-p-chip-icon"></span>
    <div>
      <div class="my-p-chip-text">
        {{
          oznaka.get('punNaziv').value
            ? oznaka.get('punNaziv').value
            : oznaka.get('naziv').value
        }}
      </div>
      <div class="my-p-chip-text" *ngIf="oznaka.get('kolicina').value">
        {{
          '[' +
            oznaka.get('nazivStavke').value +
            ']' +
            ' x ' +
            [oznaka.get('kolicina').value]
        }}
      </div>
      <div class="my-p-chip-text" *ngIf="!oznaka.get('kolicina').value">
        [Ceo račun]
      </div>
    </div>
    <span
      class="far fa-times-circle my-p-chip-remove-icon"
      (click)="onRemoveOznakaRacunaClicked(i, oznaka.value)"
      pTooltip="Ukloni oznaku iz računa"
      tooltipPosition="bottom"
    ></span>
  </ng-container>
</div>
