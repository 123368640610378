<div
  class="akcije-fiskalnog-racuna-card p-d-flex p-jc-between"
  [ngClass]="applyClass"
  (click)="action()"
>
  <div class="p-d-flex justify-content">
    <div class="k-icon">
      <i [ngClass]="'k-icon ' + cardIcon"></i>
    </div>
    <div class="p-d-flex p-flex-column p-ml-2">
      <div>
        <h5 class="_header">
          {{ cardTitle }}
        </h5>
      </div>
      <div class="p-text-nowrap p-text-truncate _subtitle">
        <span
          class="_value"
          [ngClass]="cardSubtitle === 'Izaberi' ? '_notselected' : '_selected'"
          >{{ cardSubtitle }}</span
        >
      </div>
    </div>
  </div>
  <div class="p-d-flex align-items-end arrows">
    <i class="fa-solid fa-up-down"></i>
  </div>
</div>
