import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-error-info-required',
  templateUrl: './error-info-required.component.html',
  styleUrls: ['./error-info-required.component.scss'],
})
export class ErrorInfoRequiredComponent implements OnInit {
  /** Props */
  errorMsg: string = 'Obavezno polje';

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor() {}

  ngOnInit(): void {}
}
