import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@kodit/core/services';

@Component({
  selector: 'kodit-tawk-chat',
  templateUrl: './tawk-chat.component.html',
  styleUrls: ['./tawk-chat.component.scss'],
})
export class TawkChatComponent implements OnInit {
  // documentation: https://developer.tawk.to/jsapi/

  private readonly id: string;
  script = this._renderer.createElement('script');

  constructor(
    private _renderer: Renderer2,
    private _authService: AuthService,
    @Inject(DOCUMENT) private _document
  ) {
    this.id = '64a66fe894cf5d49dc61d9af/1h4l2a91f';
  }

  ngOnInit() {
    this.script.text = `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    Tawk_API.visitor = {
      name : '${this._authService.getCurrentTenantName}',
      email : '${this._authService.getUserData.email}',
    };
    
    Tawk_API.onLoad = function() {
      window.Tawk_API.setAttributes({
          'organization'    : '${this._authService.getCurrentTenant}',
          'tag' : '${this._authService.getCurrentTenantName}',
          'ime_osobe': '${this._authService.getUserData.fullName}',
          'name'  : '${this._authService.getUserData.fullName}',
          'email' : '${this._authService.getUserData.email}',
          'id'  : '${this._authService.getCurrentTenant}',
      }, function(error){});
      
      window.Tawk_API.addTags([
        '${this._authService.getCurrentTenant}',
        '${this._authService.getCurrentTenantName}'],
      function(error){});
    };
        
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/${this.id}';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();`;
    this._renderer.appendChild(this._document.body, this.script);
  }
}
