<div style="border-left: 1px solid #e9ecef" class="table-actions">
    <div
            style="visibility: hidden; width: 30.984px"
            *ngIf="availableActions.length < 2 && availableMultiActions?.length !== 0"
    ></div>

    <div
            style="visibility: hidden; width: 30.984px"
            *ngIf="availableActions.length < 1 && availableMultiActions?.length !== 0"
    ></div>

    <span
            *ngFor="let action of availableActions"
            [pTooltip]="action.tooltip"
            tooltipPosition="bottom"
    >
    <kodit-button
            *ngIf="action.type !== dropdownActionType"
            [btnIcon]="action.icon | tableActionIcon: action.type"
            [btnClass]="
        'p-ai-baseline ' + action.actionClass | tableRowActionClass: action.type
      "
            [shouldDisableWhenSefInactive]="action.shouldDisableWhenSefInactive"
            [isBtnDisabled]="!action.isAvailable"
            [btnLabel]="
        action.type == noActionsType ? 'Nema dostupnih akcija' : action.label
      "
            (btnClicked)="checkAction(action)"
    ></kodit-button>

    <div *ngIf="action.type === dropdownActionType">
      <kodit-button
              [btnIcon]="action.icon | tableActionIcon: action.type"
              [btnClass]="
          'p-ai-baseline ' + action.actionClass
            | tableRowActionClass: action.type
        "
              (btnClicked)="opDropdown.toggle($event)"
      ></kodit-button>
    </div>
  </span>

    <span
            *ngIf="
      availableActions?.length === 0 && availableMultiActions?.length === 0
    "
            class="no-actions"
    >
    Nema akcija
  </span>

    <kodit-button
            *ngIf="availableMultiActions?.length !== 0"
            btnIcon="fa-regular fa-ellipsis-vertical"
            btnClass="p-button-text"
            btnTooltip="Dodatne akcije"
            (btnClicked)="opMultiAction.toggle($event)"
    >

    </kodit-button>
</div>

<p-overlayPanel
    #opMultiAction
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
>
    <ng-template pTemplate>
        <div *ngFor="let action of availableMultiActions" class="action-wrapper">
            <kodit-button

                    [btnLabel]="
                action.type == noActionsType ? 'Nema dostupnih akcija' : action.label
              "
                    [btnIcon]="action.icon | tableActionIcon: action.type"
                    [btnClass]="
                'p-ai-baseline table-row-action ' + action.actionClass
                  | tableRowActionClass: action.type
              "
                    [shouldDisableWhenSefInactive]="action.shouldDisableWhenSefInactive"
                    [isBtnDisabled]="!action.isAvailable"
                    [btnTooltip]="action.tooltip"
                    btnTooltipPosition="left"
                    (btnClicked)="checkAction(action)"
            >
            </kodit-button>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel
    #opDropdown
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
>
    <ng-template pTemplate>
    <span
            *ngFor="let dropdownItem of availableDropdownActions"
            [pTooltip]="dropdownItem.hasAccessTooltip"
            tooltipPosition="left"
            class="p-d-flex p-flex-column m-action-wrapper"
    >
      <a
              class="m-action"
              type="button"
              pButton
              pRipple
              [icon]="dropdownItem.icon | tableActionIcon: dropdownItem.type"
              [ngClass]="
          'p-ai-baseline ' + dropdownItem.actionClass
            | tableRowActionClass: dropdownItem.type
        "
              (click)="checkAction(dropdownItem)"
              [disabled]="!dropdownItem.isAvailable"
              [label]="
          dropdownItem.type == noActionsType
            ? 'Nema dostupnih akcija'
            : dropdownItem.label
        "
      ></a>
    </span>
    </ng-template>
</p-overlayPanel>
