<kodit-card-container [showHeader]="false" [showDivider]="false">
  <div card-body>
    <div class="p-d-flex p-flex-column">
      <div class="api-title">API podešavanja</div>
      <div class="p-d-flex p-flex-column p-mt-4">
        <div class="subtitle">Linkovi za API endpoint-e</div>
        <div class="p-d-flex p-flex-row p-mt-3">
          <a href="{{environmentUrl + links[0]}}" class="p-mr-4"> eKompanija API </a>
          <a href="{{environmentUrl + links[1]}}" class="p-mr-4"> Fiskalna kasa API </a>
          <a href="{{environmentUrl + links[2]}}" class="p-mr-4"> Mobile API </a>
          <a href="{{environmentUrl + links[3]}}" class="p-mr-4"> Website API </a>
        </div>
      </div>
      <div class="p-d-flex p-flex-column p-mt-4">
        <div class="subtitle">Ključ za autentifikaciju</div>
        <div class="p-d-flex p-flex-row p-ai-center p-mt-3">
          <input pInputText class="api-key" [disabled]="true" [placeholder]="apiKey ?? ''"/>
          <i class="fa-solid fa-copy copy-icon p-ml-3" (click)="copyApiKey()"></i>
        </div>
        <div class="p-mt-3">
          <kodit-button btnLabel="{{apiKey === null ? 'Generiši' : 'Obnovi'}}" (click)="generisiApiKey()"></kodit-button>
        </div>
      </div>
      <div class="api-title p-mt-4">API status</div>
    </div>
    <div class="p-mt-4 api-status" [ngClass]="apiStatus ? 'aktivan' : 'neaktivan'">
      {{apiStatus ? 'Aktivan' : 'Neaktivan'}}
    </div>
  </div>
</kodit-card-container>
