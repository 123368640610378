import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActionClass,
  ActionType,
  DynamicDialogConfig,
  DynamicDialogRef,
  DynamicDialogService,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { ArhivskaKnjigaFormComponent } from '../arhivska-knjiga-form/arhivska-knjiga-form.component';
import {
  ArhivskaKnjigaClient,
  ArhivskaKnjigaTableItemDto,
  ResultOfArhivskaKnjigaTableItemDto,
  UnlockArhivskaEditCommand,
} from '@kodit/core/data-api';
import {
  AlertService,
  GodinaRacunaDropdownService,
} from '@kodit/core/services';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { ArhivskaPdfConfigFormComponent } from '../arhivska-pdf-config-form/arhivska-pdf-config-form.component';

@Component({
  selector: 'kodit-arhivska-knjiga-table',
  templateUrl: './arhivska-knjiga-table.component.html',
  styleUrls: ['./arhivska-knjiga-table.component.scss'],
})
export class ArhivskaKnjigaTableComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  tableConfig!: TableConfig;
  unosDialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_LARGE
  );
  stampanjeDialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.SMALL
  );
  izmenaDialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_LARGE
  );
  dialogConfig : DynamicDialogConfig = new DynamicDialogConfig(DialogSize.MEDIUM_LARGE);
  arhivskeTable: ArhivskaKnjigaTableItemDto[] = [];
  naredniRedniBroj: number = 0;

  constructor(
    private _dialogService: DynamicDialogService,
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _godinaDropdownService: GodinaRacunaDropdownService,
    private _arhivskaService: ArhivskaKnjigaService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();
    this._loadTable();

    this._subs.add(
      this._godinaDropdownService.getForm().valueChanges.subscribe((res) => {
        this._loadTable();
      })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Arhivska knjiga',
      selectMode: SelectMode.MULTI,
      columns: [
        {
          field: 'redniBroj',
          header: 'Redni broj',
          type: 'text',
        },
        {
          field: 'datumUpisa',
          header: 'Datum upisa',
          type: 'text',
        },
        {
          field: 'godinaNastanka',
          header: 'Godina nastanka',
          type: 'text',
        },
        {
          field: 'sadrzaj',
          header: 'Sadržaj',
          type: 'text',
        },
        {
          field: 'fizickaLokacija',
          header: 'Fizička lokacija',
          type: 'text',
        },
        {
          field: 'rokCuvanja',
          header: 'Rok čuvanja',
          type: 'number',
        },
        {
          field: 'kolicinaDokumentarnogMaterijala',
          header: 'Količina dok. materijala',
          type: 'number',
        },
      ],
      headerActions: [
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.OUTLINED,
          icon: 'fa-regular fa-lock-open',
          label: 'Otključaj izmene',
          callback: (res: ArhivskaKnjigaTableItemDto[]) => {
            this._enableArhivskaEdit(res.map((arhivska) => arhivska.id!));
          },
        },
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          icon: 'fa-regular fa-print',
          hasAccessTooltip: 'Štampanje arhivskih dokumenata',
          callback: () => {
            this.stampanjeDialogConfig.header =
              'Konfigurisanje PDF-a Arhivske knjige';
            this._dialogService.open(
              ArhivskaPdfConfigFormComponent,
              this.stampanjeDialogConfig
            );
          },
        },
        {
          type: ActionType.CUSTOM,
          label: 'Unesi novu',
          icon: 'fas fa-plus',
          hasAccessTooltip: 'Unesite novu arhivsku knjigu',
          callback: () => {
            this.unosDialogConfig.header = 'Unos arhivske knjige';
            this.unosDialogConfig.data = this.naredniRedniBroj;
            this.openDialog(this.unosDialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite arhivsku knjigu',
          callback: (index: number) => {
            const forEdit = this.arhivskeTable[index];
            this.izmenaDialogConfig.data = {
              id: forEdit?.id,
            };
            this.izmenaDialogConfig.header = 'Izmena podataka arhivske knjige';
            this.openDialog(this.izmenaDialogConfig);
          },
          shouldDisplayByCondition: (rowData: ArhivskaKnjigaTableItemDto) => {
            return this._shouldDisplayArhivskaEdit(rowData.isLocked!);
          },
        },
      ],
    });
  }

  private _enableArhivskaEdit(arshvskeIds: number[]) {
    this._subs.add(
      this._arhivskaClient
        .unlockArhivskeEdit(
          new UnlockArhivskaEditCommand({
            arhivskeIds: arshvskeIds,
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages![0]);
            this._loadTable();
          } else {
            this._alertService.addFailedMsg('Dokumenti nisu uspešno izmenjeni');
          }
        })
    );
  }

  private _shouldDisplayArhivskaEdit(isDisabled: boolean) {
    return !isDisabled;
  }

  openDialog(config: DynamicDialogConfig) {
    let ref: DynamicDialogRef = this._dialogService.open(
      ArhivskaKnjigaFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((dto: ResultOfArhivskaKnjigaTableItemDto) => {
        this._loadTable();
      })
    );
  }

  private _loadTable() {
    this._subs.add(
      this._arhivskaClient.getArhivskaTable().subscribe((res) => {
        this.arhivskeTable = res.data?.arhivskaTableList!;
        this._getNextRedniBroj(this.arhivskeTable);

        this._arhivskaService.setOznaciKaoUradjeno =
          this.arhivskeTable.length !== 0;
      })
    );
  }

  private _getNextRedniBroj(tableItems: ArhivskaKnjigaTableItemDto[]) {
    let maxVal: number = 0;

    tableItems.forEach((obj) => {
      if (obj.redniBroj! > maxVal) {
        maxVal = obj.redniBroj!;
      }
    });

    this._arhivskaService.setNaredniRedniBroj = maxVal;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
