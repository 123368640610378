<div class="p-d-flex p-flex-wrap" [formGroup]="form">
  <ng-container formArrayName="povezaniDokumenti">
    <div
      class="p-mt-3 p-mr-2 p-mb-2 my-p-chip"
      *ngFor="let vezani of form.controls.povezaniDokumenti?.value; index as i"
    >
      <ng-container [formGroupName]="i">
        <span class="fas fa-tag my-p-chip-icon"></span>
        <div>
          <div class="my-p-chip-text">
            {{ vezani.label }}
          </div>
        </div>
        <span
        *ngIf="!shouldDisable"
          class="far fa-times-circle my-p-chip-remove-icon"
          (click)="onRemovePovezanAvansniRacun(i)"
          pTooltip="Ukloni avansni"
          tooltipPosition="bottom"
        ></span>
      </ng-container>
    </div>
  </ng-container>
</div>
