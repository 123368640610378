import { Component, Input, OnInit } from '@angular/core';
import { ProgressItem, ProgressService } from '../../progress.service';
import { Subscription } from 'rxjs';
import { VrstaJoba } from '@kodit/core/data-api';

@Component({
  selector: 'kodit-progress-item',
  templateUrl: './progress-item.component.html',
  styleUrls: ['./progress-item.component.scss'],
})
export class ProgressItemComponent implements OnInit {
  @Input('progress') progressItem: ProgressItem;
  progressPercent: number;
  naziv: string;
  private _subs: Subscription = new Subscription();

  constructor(private _progressService: ProgressService) {}

  ngOnInit(): void {
    this._subs.add(
      this._progressService.getBehaviorSubjectForVrstaJoba(this.progressItem.vrstaJoba)
        .subscribe(procenat => {
          this.progressPercent = Math.round(procenat);
        })
    );
    if(this.progressItem.vrstaJoba == VrstaJoba.KREIRANJE_RACUNA){
      this.naziv = 'Automatsko kreiranje racuna od odobrenih SEF racuna'
    }
  }

  // startTour() {
  //   this._joyrideHandleService.startTutorial(this.tutorijal.steps);
  // }
}
