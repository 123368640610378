import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { flattenDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ActionType, TableAction, TableConfig } from '../table-common';

@Component({
  selector: 'kodit-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent implements OnInit, OnDestroy {
  /** Subs */
  private _updateActionsSub: Subscription = new Subscription();

  /** Props */
  noActionsType: ActionType = ActionType.NO_ACTIONS;
  dropdownActionType: ActionType = ActionType.DROPDOWN;

  /** I/O */
  @Input() item: any;
  @Input() rowIndex: number;
  @Input() tableConfig: TableConfig;
  @Input() parent: any;

  @Input() singleActions: any[];
  @Input() multiActions: any[];

  availableActions: any[] = [];
  availableMultiActions: any[] = [];
  availableDropdownActions: any[] = [];

  constructor(private _confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this._setAvailableActions();

    this._updateActionsSub = this.tableConfig.getUpdateActions?.subscribe(
      (index) => {
        if (index > -1 && this.rowIndex == index) {
          this._setAvailableActions();
        }
      }
    );
  }

  onClickDropdownAction(event: any) {
    // this.tableConfig.moreActionsItemIndex = rowIndex;
    // menu.toggle(event);
    alert('test');
  }

  checkAction(action: TableAction) {
    if (action.type !== ActionType.DELETE) {
      action.callback(this.rowIndex);
      return;
    }
    this.onDelete(action);
  }

  onDelete(action: TableAction) {
    const params = this.tableConfig.deleteMessageParams?.split(',');
    if (!params) {
      // u slucaju kada je 'simple' delete bez potreba za potvrdu
      action.callback(this.rowIndex);
      return;
    }

    let param = '';
    params?.forEach((p) => {
      param += this.item[p];
    });

    this._confirmationService.confirm({
      message: this.tableConfig.deleteMessage?.replace('{param}', param),
      header: 'Potvrdite brisanje',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      accept: () => {
        action.callback(this.rowIndex);
      },
    });
  }

  private _setAvailableActions() {
    this.singleActions.forEach((a) => {
      a.isVisible = a.shouldDisplay(this.item);
    });

    this.multiActions?.forEach((a) => {
      a.isVisible = a.shouldDisplay(this.item);
    });

    this.availableActions = this.singleActions.filter((a) => a.isVisible);
    this.availableMultiActions = this.multiActions.filter((a) => a.isVisible);
    this.availableDropdownActions = flattenDeep(
      this.availableActions
        .filter((x) => x.dropdownActions?.length > 0)
        .map((x) => x.dropdownActions)
    );
  }

  ngOnDestroy(): void {
    this._updateActionsSub?.unsubscribe();
  }
}
