<div card class="k-card p-mt-3">
  <div class="p-d-flex p-jc-between _header">
    <div class="ek-w-60">
      <p class="_title p-mb-2">Oznake</p>
    </div>
    <button
      class="p-mr-2 p-button p-button-outlined"
      icon="fa-light fa-plus"
      label="Dodaj oznaku/e"
      pButton
      type="button"
      (click)="openAddOznakaDialog()"
    ></button>
  </div>
  <kodit-divider></kodit-divider>
  <div *ngIf="oznake && oznake.length > 0" class="p-d-flex p-flex-wrap kodit-chip-container" card-body>
    <ng-container>
      <div
        class="p-mr-2 p-mb-2 my-p-chip"
        *ngFor="let oznaka of oznake; index as i"
      >
        <ng-container>
          <span class="fas fa-tag my-p-chip-icon"></span>
          <div>
            <div class="my-p-chip-text">
              {{ oznaka.punNaziv ? oznaka.punNaziv : oznaka.naziv }}
            </div>
            <div class="my-p-chip-text" *ngIf="oznaka.kolicina">
              {{ '[' + oznaka.nazivStavke + ']' + ' x ' + [oznaka.kolicina] }}
            </div>
            <div class="my-p-chip-text" *ngIf="!oznaka.kolicina">
              [Ceo račun]
            </div>
          </div>
          <span
            *ngIf="canDelete || !oznaka.kolicina"
            class="far fa-times-circle my-p-chip-remove-icon"
            pTooltip="Ukloni oznaku iz računa"
            tooltipPosition="bottom"
            (click)="onRemoveOznaka(i, oznaka)"
          ></span>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!oznake || oznake.length === 0" card-body>
    Ni jedna oznaka nije vezana za račun.
  </div>
</div>
