import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IKonfiguracijaMojeFirmeDto,
  MojaFirmaClient,
  MojaFirmaDto,
  ResultOfKonfiguracijaMojeFirmeDto,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { MojaFirmaDodatneInformacijeFormComponent } from '../moja-firma-dodatne-informacije-form/moja-firma-dodatne-informacije-form.component';
import {InitService} from "../../../../../../core-ui/src/lib/init.service";

@Component({
  selector: 'kodit-moja-firma-dodatne-informacije',
  templateUrl: './moja-firma-dodatne-informacije.component.html',
  styleUrls: ['./moja-firma-dodatne-informacije.component.scss'],
})
export class MojaFirmaDodatneInformacijeComponent implements OnInit, OnDestroy {
  /** Subs */
  private _loadSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  dodatneInfoDto: IKonfiguracijaMojeFirmeDto;
  uSistemuPDVText: string;
  mojaFirma: MojaFirmaDto;
  uploadCompleted: boolean;
  logo: string;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: MojaFirmaClient,
    private _route: ActivatedRoute,
    private _initService : InitService
  ) {}

  ngOnInit(): void {
    this.mojaFirma = this._route.snapshot.data.mojaFirma;
    this.load();
  }

  onEdit() {
    this.dialogConfig.data = {
      dodatneInfoDto: this.dodatneInfoDto,
    };
    this.dialogConfig.header = 'Izmena dodatnih informacija';
    this.openDialog(this.dialogConfig);
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(
      MojaFirmaDodatneInformacijeFormComponent,
      config
    );

    this._dialogCloseSub = ref.onClose.subscribe(
      (dto: ResultOfKonfiguracijaMojeFirmeDto) => {
        if (dto) {
          this.dodatneInfoDto = dto.data;
          this._initService.setKonfiguracijuMojeFirme = dto.data;
          this.mojaFirma.adresa = `${this.dodatneInfoDto.ulicaIBroj}, ${this.dodatneInfoDto.postanskiBroj} ${this.dodatneInfoDto.grad}`;
        }
      }
    );
  }

  private load() {
    this._loadSub = this._client.getKonfiguracija().subscribe(
      (result) => {
        this.dodatneInfoDto = result.data;
      },
      (error) => {
        error;
      }
    );
  }

  ngOnDestroy() {
    this._loadSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
  }
}
