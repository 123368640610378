import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  ArhivskaKnjigaClient,
  CreateArhivskaKnjigaCommand,
  FizickaLokacijaAutocompleteItem,
  ICreateArhivskaKnjigaCommand,
  UpdateArhivskaCommand,
} from '@kodit/core/data-api';
import { ArhivskaKnjigaService } from '../../../../podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { AlertService, ButtonService, Mode } from '@kodit/core/services';
import { FormHelper } from '@kodit/core/form-definitions';

@Component({
  selector: 'kodit-arhivska-knjiga-form',
  templateUrl: './arhivska-knjiga-form.component.html',
  styleUrls: ['./arhivska-knjiga-form.component.scss'],
})
export class ArhivskaKnjigaFormComponent implements OnInit, OnDestroy {
  /** Props */
  form!: FormGroupTypeSafe<ICreateArhivskaKnjigaCommand>;
  minValueRedniBroj: number | undefined;
  godineItems: string[] = [];
  formSubmited: boolean = false;
  naredniRedniBroj: number;
  mode: Mode;
  //** Subs */
  private _subs: Subscription = new Subscription();

  constructor(
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _arhivskaService: ArhivskaKnjigaService,
    private _buttonService: ButtonService,
    private _formHelper: FormHelper,
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSave();
        this._arhivskaService.setNaredniRedniBroj = this.naredniRedniBroj + 1;
      })
    );

    if (this._dialogConfig.data.id) {
      this._load(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    } else {
      this._dialogConfig.data = null;
      this.mode = Mode.NEW;
    }

    this._subs.add(
      this._arhivskaService.getNaredniRedniBroj.subscribe((res) => {
        this.naredniRedniBroj = res;
      })
    );

    this.form = this._arhivskaService.getArhivskaForm();

    this._initGodineDropdown();
    this._arhivskaService.setNextRedniBrojValue(this.naredniRedniBroj);
  }

  onSave() {
    this._arhivskaService.setArhivskaFormaSubmitted = true;

    if (this.form.invalid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._buttonService.disableButton();

    if (this.mode === Mode.NEW) {
      this._subs.add(
        this._arhivskaClient
          .createArhivskaKnjiga(this.form.value as CreateArhivskaKnjigaCommand)
          .subscribe((res) => {
            if (res.succeeded) {
              this._alertService.addSuccessMsg(res.messages![0]);
              this._dialogRef.close();
            } else {
              this._alertService.addFailedMsg(res.messages![0]);
              this._buttonService.enableButton();
            }
          })
      );
    } else {
      this._subs.add(
        this._arhivskaClient
          .updateArhivska(this.form.value as UpdateArhivskaCommand)
          .subscribe((res) => {
            if (res.succeeded) {
              this._alertService.addSuccessMsg(res.messages![0]);
              this._arhivskaService.setOznaciKaoUradjeno = true;
              this._dialogRef.close();
            } else {
              this._alertService.addFailedMsg(res.messages![0]);
              this._buttonService.enableButton();
            }
          })
      );
    }
  }

  onFizickaLokacijaChange(item: FizickaLokacijaAutocompleteItem) {
    this.form.controls.fizickaLokacijaDto?.patchValue(item);
  }

  ngOnDestroy(): void {
    this._arhivskaService.setArhivskaFormaSubmitted = false;
    this._subs.unsubscribe();
  }

  private _load(id: number) {
    this._subs.add(
      this._arhivskaClient.getArhivskaById(id).subscribe((res) => {
        this.form.patchValue(res.data!);
      })
    );
  }

  private _initGodineDropdown() {
    const currentYear = new Date().getFullYear();

    this.form.controls.godinaNastanka?.patchValue(currentYear.toString());

    for (let i = currentYear; i >= 1990; i--) {
      this.godineItems.push(i.toString());
    }
  }
}
