import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Modules */
import { PdvEvidencijaRoutingModule } from './pdv-evidencija-routing.module';
import { CoreSharedModule } from '@kodit/core/shared';

/** Components */
import { PdvEvidencijaPojedinacnaFormComponent } from './pojedinacna/pdv-evidencija-pojedinacna-form/pdv-evidencija-pojedinacna-form.component';
import { PdvEvidencijaPojedinacnaTableComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-table.component';
import { PdvEvidencijaPageComponent } from './pdv-evidencija-page/pdv-evidencija-page.component';
import { TipDokumentaEvidencijeDropdownComponent } from './ui/tip-dokumenta-evidencije-dropdown/tip-dokumenta-evidencije-dropdown';
import { PdvOpsegDropdownComponent } from './ui/pdv-opseg-dropdown/pdv-opseg-dropdown';
import { PdvPeriodDropdownComponent } from './ui/pdv-period-dropdown/pdv-period-dropdown';
import { StrankaForPdvAutocompleteComponent } from './ui/stranka-for-pdv-autocomplete/stranka-for-pdv-autocomplete';
import { DokumentForPdvAutocompleteComponent } from './ui/dokument-for-pdv-autocomplete/dokument-for-pdv-autocomplete';
import { PdvEvidencijaZbirnaFormComponent } from './zbirna/pdv-evidencija-zbirna-form/pdv-evidencija-zbirna-form.component';
import { PdvEvidencijaZbirnaTableComponent } from './zbirna/pdv-evidencija-zbirna-table/pdv-evidencija-zbirna-table.component';
import { PovezaniAvansniRacuniComponent } from './pojedinacna/povezani-avansni-racuni/povezani-avansni-racuni.component';
import { OsnovOdabiraDokumentaDropdownComponent } from './ui/osnov-odabira-dokumenta-dropdown/osnov-odabira-dokumenta-dropdown.component';
import { PdvEvidencijaPojedinacnaFilterComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-filter/pdv-evidencija-pojedinacna-filter.component';
import { TipDokumentaEvidencijeMultiselectComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-filter/tip-dokumenta-evidencije-multiselect/tip-dokumenta-evidencije-multiselect.component';
import { StatusEvidencijeMultiselectComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-filter/status-evidencije-multiselect/status-evidencije-multiselect.component';
import { PdvEvidencijaZbirnaFilterComponent } from './zbirna/pdv-evidencija-zbirna-table/pdv-evidencija-zbirna-filter/pdv-evidencija-zbirna-filter.component';

@NgModule({
  imports: [PdvEvidencijaRoutingModule, CoreSharedModule],
  declarations: [
    PdvEvidencijaPojedinacnaFormComponent,
    PdvEvidencijaPojedinacnaTableComponent,
    PdvEvidencijaPageComponent,
    TipDokumentaEvidencijeDropdownComponent,
    PdvOpsegDropdownComponent,
    PdvPeriodDropdownComponent,
    StrankaForPdvAutocompleteComponent,
    DokumentForPdvAutocompleteComponent,
    PdvEvidencijaZbirnaFormComponent,
    PdvEvidencijaZbirnaTableComponent,
    PovezaniAvansniRacuniComponent,
    OsnovOdabiraDokumentaDropdownComponent,
    PdvEvidencijaPojedinacnaFilterComponent,
    TipDokumentaEvidencijeMultiselectComponent,
    StatusEvidencijeMultiselectComponent,
    PdvEvidencijaZbirnaFilterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliPdvEvidencijaModule {}
