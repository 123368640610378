import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ArhivskaKnjigaClient,
  GetArhivskaTablePdfQuery,
  IArhivskaPdfRequestDto,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, ButtonService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import * as printJS from 'print-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-arhivska-pdf-config-form',
  templateUrl: './arhivska-pdf-config-form.component.html',
  styleUrls: ['./arhivska-pdf-config-form.component.scss'],
})
export class ArhivskaPdfConfigFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  godineItems: string[] = [];
  form!: FormGroupTypeSafe<IArhivskaPdfRequestDto>;

  constructor(
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _butonService: ButtonService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSave();
      })
    );

    this.form = this._arhivskaService.getArhivskaPdfRequestForm();
    this._initGodineDropdown();
  }

  private _initGodineDropdown() {
    const currentYear = new Date().getFullYear();
    this.form.controls.godinaDokumenta?.patchValue(currentYear.toString());
    for (let i = currentYear; i >= 1990; i--) {
      this.godineItems.push(i.toString());
    }
  }

  onSave() {
    if (this.form.invalid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (
      this.form.controls.redniBrojOd?.value! >
      this.form.controls.redniBrojDo?.value!
    ) {
      this._alertService.addWarnMsg(
        " 'Broj od' ne može biti veći od 'Broj do' "
      );
      return;
    }

    this._butonService.disableButton();

    this._subs.add(
      this._arhivskaClient
        .getArhivskaPdf(
          new GetArhivskaTablePdfQuery({
            redniBrojOd: this.form.value.redniBrojOd,
            redniBrojDo: this.form.value.redniBrojDo,
            godinaDokumenta: this.form.value.godinaDokumenta,
          })
        )
        .subscribe((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.download = 'arhivske-knjige.pdf';
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);

          this._dialogRef.close(location.reload());
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
