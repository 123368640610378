import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormControl, AbstractControl } from '@angular/forms';
import { VrstaRacuna } from '@kodit/core/data-api';

@Component({
  selector: 'kodit-vrsta-racuna-dropdown',
  templateUrl: './vrsta-racuna-dropdown.component.html',
  styleUrls: ['./vrsta-racuna-dropdown.component.scss'],
})
export class VrstaRacunaDropdownComponent implements OnInit {
  //** Props */
  items: SelectItem[];

  /** I/O */
  @Input() formCtrl: FormControl | AbstractControl;
  @Input() parent: any = 'body';
  @Input() shouldDisable: boolean = false;
  @Output() onItemChange = new EventEmitter<VrstaRacuna>();

  constructor() {
    this.items = [
      { label: 'Izaberite smer dokumenta', value: VrstaRacuna.EMPTY },
      { label: 'Isporuka', value: VrstaRacuna.IZLAZNI },
      { label: 'Nabavka', value: VrstaRacuna.ULAZNI },
    ];
  }

  ngOnInit(): void {}

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }
}
