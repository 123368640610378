<div class="layout-footer">
  <div class="footer-logo-container">
    <img
      id="footer-logo"
      src="/assets/core-ui/images/ek-logotip-final-gray.png"
      alt="kodit-logo"
    />
    <a class="p-ml-2 verzija" (click)="openVerzijaSoftveraDijalog()" target="_blank">Verzija</a>
  </div>
  <span class="copyright"
    >&#169; <a href="https://www.kodit.rs/" target="_blank">Kodit</a> -
    {{ currentYear }}</span
  >
</div>
