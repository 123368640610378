import { Injectable } from '@angular/core';
import {
  IDokumentForPdvDto,
  IPdvEvidencijaPojedinacnaDto,
  IStrankaForPdvDto,
  PdvEvidencijaTipDokumenta,
  TipRacuna,
  VezaniRacunDto,
} from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { PdvEvidencijaService } from '../pdv-evidencija.service';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaPojedinacnaService {
  constructor(private _pdvService: PdvEvidencijaService) {}

  pdvForm$ = new BehaviorSubject<PdvForm>(null);
  povezaniDokumenti$ = new BehaviorSubject<VezaniRacunDto[]>([]);

  set setPdvForm(model: PdvForm) {
    this.pdvForm$.next(model);
  }

  get getPdvFormObject(): PdvForm {
    return this.pdvForm$.getValue();
  }

  // get getUkupanIznos(): number {
  //   return this.getPdvFormObject?.value.ukupanIznos ?? 0;
  // }

  set setUkupanIznos(ukupanIznos: number | undefined) {
    if (!ukupanIznos) {
      return;
    }
    this.getPdvFormObject?.controls.ukupanIznos?.patchValue(ukupanIznos);
  }

  set setUkupanIznosNaknade(ukupanIznosNaknade: number | undefined) {
    if (!ukupanIznosNaknade) {
      return;
    }
    this.getPdvFormObject?.controls.ukupanIznosNaknade?.patchValue(
      ukupanIznosNaknade
    );
  }

  set setOsnovica10(osnovica10: number | undefined) {
    if (osnovica10 === null || osnovica10 === undefined || isNaN(osnovica10)) {
      return;
    }
    this.getPdvFormObject?.controls.osnovica10?.patchValue(osnovica10);
  }

  set setOsnovica20(osnovica20: number | undefined) {
    if (osnovica20 === null || osnovica20 === undefined || isNaN(osnovica20)) {
      return;
    }
    this.getPdvFormObject?.controls.osnovica20?.patchValue(osnovica20);
  }

  set setObracunatPdv(obracunatPdv: number | undefined) {
    if (
      obracunatPdv === null ||
      obracunatPdv === undefined ||
      isNaN(obracunatPdv)
    ) {
      return;
    }
    this.getPdvFormObject?.controls.obracunatPdv?.patchValue(obracunatPdv);
  }

  set setDatumPrometa(datumPrometa: Date | undefined) {
    if (!datumPrometa) {
      return;
    }
    this.getPdvFormObject?.controls.datumPrometa?.patchValue(datumPrometa);
  }

  set setDatumValute(datumValute: Date | undefined) {
    if (!datumValute) {
      return;
    }
    this.getPdvFormObject?.controls.datumValute?.patchValue(datumValute);
  }

  get getStranka(): StrankaForm {
    return this.getPdvFormObject?.getSafe<IStrankaForPdvDto>(
      (x) => x.stranka
    ) as StrankaForm;
  }

  set setStranka(stranka: IStrankaForPdvDto | undefined) {
    if (!stranka) {
      this.getStranka.reset();
      return;
    }
    this.getStranka.patchValue(stranka);
  }

  get getDokument(): DokumentForm {
    return this.getPdvFormObject?.getSafe<IDokumentForPdvDto>(
      (x) => x.dokument
    ) as DokumentForm;
  }

  set setDokument(dokument: IDokumentForPdvDto | undefined) {
    if (!dokument) {
      this.getPdvFormObject?.controls.dokument?.reset();
      return;
    }
    this.getPdvFormObject?.controls.dokument?.patchValue(dokument);
    this.setTipDokumenta = dokument.tip;
  }

  set setTipDokumenta(tip: TipRacuna | undefined) {
    switch (tip) {
      case TipRacuna.AVANSNI_RACUN:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.AVANSNA_FAKTURA
        );
        break;
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.FAKTURA
        );
        break;
      case TipRacuna.DOKUMENT_O_SMANJENJU:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.DOKUMENT_O_SMANJENJU
        );
        break;
      case TipRacuna.DOKUMENT_O_POVECANJU:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.DOKUMENT_O_POVECANJU
        );
        break;
      default:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.DRUGI_INTERNI_RACUN
        );
        break;
    }
  }

  /**
   * Cuvamo vrednost da li smo kliknuli na Sacuvaj formu kako bi mogli da validiramo (BrojDokumenta i Stranka)
   */

  formSaveClicked$ = new BehaviorSubject<boolean>(false);

  set setFormSaveClicked(value: boolean) {
    this.formSaveClicked$.next(value);
  }

  get getFormSaveClickedObject() {
    return this.formSaveClicked$.asObservable();
  }

  resetDokument() {
    this.getPdvFormObject?.controls.dokument?.reset();
  }

  resetStranka() {
    this.getPdvFormObject?.controls.stranka?.reset();
  }

  resetForm() {
    this.setStranka = undefined;
    // Promet
    this.setUkupanIznos = 0;
    this.setOsnovica10 = 0;
    this.setOsnovica20 = 0;
    this.setObracunatPdv = 0;
    this.setTipDokumenta = TipRacuna.IZLAZNA_FAKTURA;
    this.resetDokument();
    this.setPdvForm = null;
  }
}

export declare type PdvForm = FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto> | null;
export declare type StrankaForm = FormGroupTypeSafe<IStrankaForPdvDto>;
export declare type DokumentForm = FormGroupTypeSafe<IDokumentForPdvDto>;
